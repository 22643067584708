import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Page,
  Products,
} from '@components'

const meta = {
  title: 'Silikatne ploče za kamin - Proizvodi | Protupožarna zaštita',
  description: 'Silikatne ploče imaju vrhunska toplinska i mehanička svojstva stoga ih je idealno koristiti u područjima gdje se često pojavljuju visoke temperature (kamini ili ložišta).',
}

const Category = () => (
  <Page
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/proizvodi/', name: 'Proizvodi'},
        { link: `/proizvodi/silikatne-ploce-za-kamin/`, name: 'Silikatne ploče za kamin'},
      ]}
    />
    <Hero
      description="Silikatne ploče imaju vrhunska toplinska i mehanička svojstva stoga ih je idealno koristiti u područjima gdje se često pojavljuju visoke temperature (kamini ili ložišta)."
      slim
      subtitle="Silikatne ploče za kamin"
      title="Proizvodi"
    />
    <Products activeCategory="silikatne-ploce-za-kamin" />
  </Page>
)

export default Category
